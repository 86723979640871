
    import { mapGetters,mapActions } from 'vuex'
  export default {
    props: {
      id: {
        default: '',
        type: String,
      },
      image: {
        default: 'dark',
        type: String,
      },
      secondary: {
        default: 'white',
        type: String,
      },
      rounded: {
        default: 'tr',
        type: String,
      },      
      center: {
        default: 'false',
        type: String,
      },
      btn: {
        default: 'Take the quiz',
        type: String,
      }, 
      modal: {
        default: 'coursequiz',
        type: String,
      }, 
      header: {
        default: 'coursequiz',
        type: String,
      },
      text: {
        default: 'coursequiz',
        type: String,
      },
      overlay: {
        default: 'https://cdn-wp.tefl.org/wp-content/uploads/2023/12/grey-curve-overlay-fixed.png',
        type: String,
      },
      img: {
        default: 'https://cdn-wp.tefl.org/wp-content/uploads/2023/12/quiz-bg-3.jpg',
        type: String,
      },
      bgposition: {
        default: 'https://cdn-wp.tefl.org/wp-content/uploads/2023/12/quiz-bg-3.jpg',
        type: String,
      },
      topmargin: {
        default: 'mt-0',
        type: String,
      },
      padding: {
        default: '!pt-10 !pb-10',
        type: String,
      }
    },
    data() {
        return {
            isActive: this.active !== ''
        }
    },
    computed: {
        ...mapGetters({
            activeModal : 'pages/getActiveModal',
        }),
    },
    methods: {
        ...mapActions({
            updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
        }),
        updateModal(id){
            const newId = this.$nuxt.$options.filters.convertModal(id)
            this.updateActiveModal(newId)
            console.log('Active Modal: ',this.activeModal)
        },
        bgimage(img) {
            // console.log('THUMBNAIL',thumbnail)
            const imgUrl = this.$img(this.$options.filters.cdn(img), { format:'webp',quality:50, width:'1920px' })
            return {
                backgroundImage: `url('${imgUrl}')`
            }
        },
        mobilebgimage(img) {
            // console.log('THUMBNAIL',thumbnail)
            const imgUrl = this.$img(this.$options.filters.cdn(img), { format:'webp',quality:50, width:'1920px' })
            return {
                backgroundImage: `url('${imgUrl}')`
            }
        },
    },
    render() {
        const slot = this.$slots.default
        // console.log('SLOt',slot)
        // return slot.text.replace(/,/g, "")
        return slot
    }
  }
  